import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentDto } from '../models/document.model';
import { PersonDto } from 'base-lib';

@Injectable()
export class DocumentService {

    constructor(private http: HttpClient) {}

    public findByPerson(person: PersonDto): Observable<DocumentDto[]> {
        return this.http.get<DocumentDto[]>(`api/person/${person.id}/document`);
    }

    public attach(person: PersonDto, document: DocumentDto): Observable<DocumentDto> {
        return this.http.post<DocumentDto>(`api/person/${person.id}/document`, document);
    }

    public detach(person: PersonDto, document: DocumentDto) {
        return this.http.delete<DocumentDto>(`api/person/${person.id}/document/${document.id}`);
    }
}
