import {Component, forwardRef} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {CompanyDto, Regex} from 'base-lib';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompanyEditorComponent),
    multi: true
};

@Component({
    selector: 'company-editor',
    templateUrl: './company-editor.component.html',
    styleUrls: ['./company-editor.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class CompanyEditorComponent implements ControlValueAccessor {

    public company: CompanyDto;
    public disabled: boolean;
    public patternSiret: string = Regex.siret;

    onChange = (value: any) => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.company = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
