import { Component, Input } from '@angular/core';
import { DocumentDto } from '../../../models/document.model';
import { FileService } from 'base-lib';

@Component({
    selector: 'document-link',
    templateUrl: './document-link.component.html',
    styleUrls: ['./document-link.component.scss']
})
export class DocumentLinkComponent {

    @Input() document: DocumentDto;

    constructor(public fileService: FileService) {}
}
