import { Input } from '@angular/core';
import { SubscriptionDto } from '../../models/subscription.model';
import { SubscriptionContext } from '../../models/subscription-context.model';
import { AbstractStepContentComponent } from 'base-lib';
import { SubscriptionMode } from '../../models/subscription-mode.mode';

export abstract class AbstractSubscriptionStepComponent extends AbstractStepContentComponent {

    @Input() subscription: SubscriptionDto;
    @Input() context: SubscriptionContext;

    public computeSubscriptionMode(): SubscriptionMode {
        if (this.subscription
            && this.subscription.parentPersonEmails
            && this.subscription.parentPersonEmails.length > 0) {
            return 'LINKING';
        }
        return 'STANDARD';
    }
}
