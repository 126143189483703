import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PersonDto, PostalAddressDto } from 'base-lib';

@Component({
    selector: 'person-list',
    templateUrl: './person-list.component.html',
    styleUrls: ['./person-list.component.scss'],
    host: {class: 'cell auto grid-y'}
})
export class PersonListComponent {

    @Input() persons: PersonDto[];
    @Output() selected: EventEmitter<PersonDto> = new EventEmitter<PersonDto>();

    public findPrincipalAddress(person: PersonDto): PostalAddressDto {
        if (!person || !person.postalAddresses) {
            return null;
        }
        return person.postalAddresses.find(address => address.principal === true);
    }
}
