import {Component, forwardRef, OnInit} from '@angular/core';
import {AbstractSubscriptionStepComponent} from '../abstract-subscription-step.component';
import {DocumentDto, DocumentKind} from '../../../models/document.model';
import {DocumentToUpload} from '../../../models/subscription-context.model';
import {Observable} from 'rxjs';
import {AbstractStepContentComponent, FilePickerComponent, FileRef, FileService} from 'base-lib';
import {SettingsDto} from '../../../models/settings.model';
import {SettingsService} from '../../../services/settings.service';
import {filter, map} from 'rxjs/operators';
import {SessionService} from 'auth-lib';

@Component({
    selector: 'subscription-additional-information',
    templateUrl: './subscription-additional-information.component.html',
    styleUrls: ['./subscription-additional-information.component.scss'],
    providers: [{
        provide: AbstractStepContentComponent,
        useExisting: forwardRef(() => SubscriptionAdditionalInformationComponent),
        multi: true
    }]
})
export class SubscriptionAdditionalInformationComponent extends AbstractSubscriptionStepComponent implements OnInit {

    public showWebCam: boolean;
    public pictureDataUrl: string;
    public personPicture: File;
    public manuallySentProofOfHandicap: boolean;
    public manuallySentProofOfAddress: boolean;
    public settings: SettingsDto;
    public isOperator: boolean;
    private DEFAULT_BACKGROUND: string = 'https://via.placeholder.com/800x384.png?text=Photo';

    constructor(private settingsService: SettingsService,
                private sessionService: SessionService,
                private fileService: FileService) {
        super();
        this.sessionService.connectedUser
            .pipe(
                filter(u => u !== undefined && u !== null),
                map(u => u.roles.indexOf('ROLE_OPERATOR') >= 0)
            )
            .subscribe(isOperator => this.isOperator = isOperator);
    }

    ngOnInit() {
        this.manuallySentProofOfHandicap = this.isOperator;
        this.manuallySentProofOfAddress = this.isOperator;
        this.settingsService
            .findSettings()
            .subscribe(settings => this.settings = settings);
        this.pictureDataUrl = this.getPictureUrl();
    }

    private getPictureUrl(): string {
        return this.subscription
        && this.subscription.person
        && this.subscription.person.pictureBinaryDataId ? this.fileService.getDownloadUrl(this.subscription.person.pictureBinaryDataId) : this.DEFAULT_BACKGROUND;
    }

    public proofOfAddressMandatory() {
        return this.isOperator ? false : (this.settings && this.settings.proofOfAddressMandatory);
    }

    public photoMandatory() {
        return this.isOperator ? false : (this.settings && this.settings.photoMandatory);
    }

    public toggleWebCam(): void {
        this.showWebCam = !this.showWebCam;
    }

    public onPictureSubmittedFromWebcam(fileRef: FileRef, personPictureFilePicker: FilePickerComponent): void {
        this.onPictureSubmitted(fileRef);
        personPictureFilePicker.deleteFile();
    }

    public onPictureSubmitted(fileRef: FileRef): void {
        if (fileRef) {
            this.showWebCam = false;
            this.context.pictureToUpload = fileRef;
            this.getPictureDataUrl()
                .subscribe(pictureDataUrl => this.pictureDataUrl = pictureDataUrl);
        }
    }

    private uploadDocument(fileRef: FileRef, kind: DocumentKind) {
        if (fileRef) {
            const documentToUpload = new DocumentToUpload();
            documentToUpload.document = new DocumentDto();
            documentToUpload.document.fileName = fileRef.file.name;
            documentToUpload.document.kind = kind;
            documentToUpload.file = fileRef;
            this.context.documentsToUpload.push(documentToUpload);
        }
    }

    public onProofOfHandicapPicked(fileRef: FileRef): void {
        if (fileRef && fileRef.file) {
            this.manuallySentProofOfHandicap = false;
            this.uploadDocument(fileRef, 'HANDICAP_PROOF');
        }
    }

    public onManuallySentProofOfHandicapPickedChange(proofOfHandicapFilePicker: FilePickerComponent): void {
        this.context.documentsToUpload = this.context.documentsToUpload
            .filter(document => document.document.kind !== 'HANDICAP_PROOF');
        proofOfHandicapFilePicker.deleteFile();
    }

    public onProofOfAddressPicked(fileRef: FileRef): void {
        if (fileRef && fileRef.file) {
            this.manuallySentProofOfAddress = false;
            this.uploadDocument(fileRef, 'ADDRESS_PROOF');
        }
    }

    public onManuallySentProofOfAddressChange(proofOfAddressFilePicker: FilePickerComponent) {
        this.context.documentsToUpload = this.context.documentsToUpload
            .filter(document => document.document.kind !== 'ADDRESS_PROOF');
        proofOfAddressFilePicker.deleteFile();
    }

    private getPictureDataUrl(): Observable<string> {
        return Observable.create(subscriber => {
            const reader = new FileReader();
            reader.onload = (e) => subscriber.next(e.target['result']);
            reader.readAsDataURL(this.context.pictureToUpload.file);
        });
    }
}
