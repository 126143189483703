import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgModelGroup} from '@angular/forms';
import {GeoLocation, NotificationService, PostalAddressDto, PostalAddressService} from 'base-lib';
import {environment} from '../../../../../environments/environment';
import {SettingsService} from '../../../../services/settings.service';
import {map} from 'rxjs/operators';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PostalAddressEditorComponent),
    multi: true
};

@Component({
    selector: 'postal-address-editor',
    templateUrl: './postal-address-editor.component.html',
    styleUrls: ['./postal-address-editor.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}]
})
export class PostalAddressEditorComponent implements OnInit, ControlValueAccessor {

    public tileServerUrl: string = environment.leaflet.tileServerUrl;
    public tileServerApiKey: string = environment.leaflet.tileServerApiKey;
    public initialLocation: GeoLocation;

    public postalAddress: PostalAddressDto;
    public disabled: boolean;
    public collapsed: boolean;

    constructor(private settingsService: SettingsService,
                private postalAddressService: PostalAddressService,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.settingsService
            .findSettings()
            .pipe(map(settings => settings.centerCity))
            .subscribe(cityCenter => this.initialLocation = cityCenter);
    }

    onChange = (_: any) => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.postalAddress = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public toggle() {
        this.collapsed = !this.collapsed;
    }

    public isValidAddress(postalAddress: PostalAddressDto): boolean {
        return postalAddress
            && postalAddress.address && postalAddress.address.length > 1
            && postalAddress.city && postalAddress.city.length > 1;
    }

    public verifyAddress() {
        this.postalAddressService.normalize(`${this.postalAddress.address} ${this.postalAddress.zipCode} ${this.postalAddress.city}`)
            .subscribe(postalAddressReturned => this.updateGeolocationPostalAddress(postalAddressReturned))
    }

    private updateGeolocationPostalAddress(postalAddressList: PostalAddressDto[]) {
        if (postalAddressList.length >= 1) {
            const postalAddressFind = postalAddressList[0];
            this.postalAddress.geoLocation = postalAddressFind.geoLocation;
            this.postalAddress.address = postalAddressFind.address;
            this.postalAddress.zipCode = postalAddressFind.zipCode;
            this.postalAddress.city = postalAddressFind.city;
            this.postalAddress.country = postalAddressFind.country;
            this.notificationService.success('notification.confirm.addressValid').subscribe();
        } else {
            this.notificationService.warning('notification.confirm.addressNotValid').subscribe();
        }
    }
}
