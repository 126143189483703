export class QuestionnaireDto {

    lineA: boolean = false;
    lineB: boolean = false;
    lineC: boolean = false;
    lineT: boolean = false;
    shuttleCity: boolean = false;
    tad: boolean = false;
    ticket: boolean = false;
    ticket10: boolean = false;
    subscription: boolean = false;

    lines: string[];
    subscriptions: string[];

    public generateReport() {
        this.lines = [];
        if (this.lineA) { this.lines.push('LINE_A'); }
        if (this.lineB) { this.lines.push('LINE_B'); }
        if (this.lineC) { this.lines.push('LINE_C'); }
        if (this.lineT) { this.lines.push('LINE_T'); }
        if (this.shuttleCity) { this.lines.push('SHUTTLE_CITY'); }
        if (this.tad) { this.lines.push('TAD'); }

        this.subscriptions = [];
        if (this.ticket) { this.subscriptions.push('TICKET'); }
        if (this.ticket) { this.subscriptions.push('TICKET_10'); }
        if (this.ticket) { this.subscriptions.push('TICKET_SUBSCRIPTION'); }
    }
}
