import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SessionService, UserDto} from 'auth-lib';
import {PersonDto} from 'base-lib';
import * as $ from 'jquery';
import {Foundation} from 'foundation-sites/js/foundation.core';
import {PersonService} from '../../services/person.service';
import {filter, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    host: {class: 'cell shrink'}
})
export class AppHeaderComponent implements OnInit, AfterViewInit {

    @ViewChild('logoutDropdown') public logoutDropdownEl: ElementRef;
    private logoutDropdown: Foundation.Dropdown;
    public user: UserDto;
    public person: PersonDto;

    constructor(private sessionService: SessionService,
                private personService: PersonService) {}

    ngOnInit(): void {
        this.sessionService.connectedUser
            .pipe(
                filter(user => user !== undefined && user !== null),
                tap(user => this.user = user),
                filter(user => user.roles.indexOf('ROLE_OPERATOR') < 0),
                switchMap(user => this.personService.findCurrentPerson())
            )
            .subscribe(person => this.person = person);
    }

    ngAfterViewInit(): void {
        this.logoutDropdown = new Foundation.Dropdown($(this.logoutDropdownEl.nativeElement));
    }

    public logout(): void {
        this.sessionService.logout().subscribe();
    }

    public isAdmin() {
        return this.user && this.user.roles.includes('ROLE_OPERATOR');
    }
}
