import { Component, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractSubscriptionStepComponent } from '../abstract-subscription-step.component';
import { environment } from '../../../../environments/environment';
import { AbstractStepContentComponent } from 'base-lib';

@Component({
    selector: 'subscription-finalization',
    templateUrl: './subscription-finalization.component.html',
    styleUrls: ['./subscription-finalization.component.scss'],
    providers: [{
        provide: AbstractStepContentComponent,
        useExisting: forwardRef(() => SubscriptionFinalizationComponent),
        multi: true
    }]
})
export class SubscriptionFinalizationComponent extends AbstractSubscriptionStepComponent {

    public pictureDataUrl: string;
    public captchaSiteKey: string = environment.captcha.siteKey;
    public generalTermsAndConditionsAccepted: boolean;

    public onEnabledStep(): void {
        super.onEnabledStep();
        if (this.context.pictureToUpload) {
            this.getPictureDataUrl()
                .subscribe(pictureDataUrl => this.pictureDataUrl = pictureDataUrl);
        }
    }

    private getPictureDataUrl(): Observable<string> {
        return Observable.create(subscriber => {
            const reader = new FileReader();
            reader.onload = (e) => subscriber.next(e.target['result']);
            reader.readAsDataURL(this.context.pictureToUpload.file);
        });
    }

    public refuseGeneralTermsAndConditions() {
        if (!this.generalTermsAndConditionsAccepted) {
            this.generalTermsAndConditionsAccepted = null;
        }
    }
}
