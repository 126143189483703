import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { PersonDto } from 'base-lib';

@Component({
    selector: 'person-cards',
    templateUrl: './person-cards.component.html',
    styleUrls: ['./person-cards.component.scss']
})
export class PersonCardsComponent {

    @Input() persons: PersonDto[];
    @ContentChild(TemplateRef) templateRef: TemplateRef<PersonDto>;
}
