import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {PersonEditorComponent} from './person/person-editor/person-editor.component';
import {PersonGenderSelectComponent} from './person/person-gender-select/person-gender-select.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import {PostalAddressesEditorComponent} from './person/postal-address/postal-addresses-editor/postal-addresses-editor.component';
import {PostalAddressEditorComponent} from './person/postal-address/postal-address-editor/postal-address-editor.component';
import {WebcamModule} from 'ngx-webcam';
import {PersonProfileComponent} from './person/person-profile/person-profile.component';
import {DocumentLinkComponent} from './person/document-link/document-link.component';
import {PersonSecurityProfileComponent} from './person/person-profile/person-security-profile/person-security-profile.component';
import {PersonCardComponent} from './person/person-card/person-card.component';
import {SubscriptionAboutComponent} from './subscription/subscription-about/subscription-about.component';
import {SubscriptionTypeComponent} from './subscription/subscription-type/subscription-type.component';
import {SubscriptionAdditionalInformationComponent} from './subscription/subscription-additional-information/subscription-additional-information.component';
import {SubscriptionTypeSelectComponent} from './subscription/subscription-type/subscription-type-select/subscription-type-select.component';
import {SubscriptionFinalizationComponent} from './subscription/subscription-finalization/subscription-finalization.component';
import {AccountAccessComponent} from './account/account-access/account-access.component';
import {RecaptchaModule} from 'ng-recaptcha';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {AccountAccessMailListComponent} from './account/account-access-mail-list/account-access-mail-list.component';
import {AppHeaderComponent} from './header/app-header.component';
import {RouterModule} from '@angular/router';
import {UserListComponent} from './user/user-list/user-list.component';
import {UserEditorComponent} from './user/user-editor/user-editor.component';
import {Foundation} from 'foundation-sites/js/foundation.core';
import {Dropdown} from 'foundation-sites/js/foundation.dropdown';
import {BaseLibModule} from 'base-lib';
import $ from 'jquery';
import {AuthLibModule} from 'auth-lib';
import {PersonListComponent} from './person/person-list/person-list.component';
import {CompanyListComponent} from './company/company-list/company-list.component';
import {CompanyEditorComponent} from './company/company-editor/company-editor.component';
import {PersonCardsComponent} from './person/person-cards/person-cards.component';
import {PersonStateComponent} from './person/person-state/person-state.component';
import {PersonDocumentsComponent} from './person/person-documents/person-documents.component';
import {SubscriptionQuestionnaireComponent} from './subscription/subscription-finalization/subscription-questionnaire/subscription-questionnaire.component';
import {PostalAddressSearchComponent} from './person/postal-address/postal-address-search/postal-address-search.component';
import {CompanySelectComponent} from './company/company-select/company-select.component';
import {BookingQuotasComponent} from './person/booking-quotas/booking-quotas.component';

Foundation.addToJquery($);
Foundation.plugin(Dropdown, 'Dropdown');

const components: any[] = [
    AppHeaderComponent,
    SubscriptionTypeSelectComponent,
    SubscriptionTypeComponent,
    SubscriptionAboutComponent,
    SubscriptionAdditionalInformationComponent,
    SubscriptionFinalizationComponent,
    AccountAccessComponent,
    PersonEditorComponent,
    PersonGenderSelectComponent,
    PostalAddressesEditorComponent,
    PostalAddressEditorComponent,
    PersonProfileComponent,
    DocumentLinkComponent,
    PersonSecurityProfileComponent,
    PersonCardComponent,
    AccountAccessMailListComponent,
    UserListComponent,
    UserEditorComponent,
    PersonListComponent,
    CompanyListComponent,
    CompanyEditorComponent,
    PersonCardsComponent,
    PersonStateComponent,
    PersonDocumentsComponent,
    SubscriptionQuestionnaireComponent,
    PostalAddressSearchComponent,
    CompanySelectComponent,
    BookingQuotasComponent
];

const pipes: any[] = [];

const directives: any[] = [];

@NgModule({
    imports: [
        // 3rd party modules
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        ToastrModule,
        DpDatePickerModule,
        WebcamModule,
        RecaptchaModule,
        RecaptchaFormsModule,

        // libs
        BaseLibModule.forChild(),
        AuthLibModule.forChild(),
    ],
    providers: [],
    declarations: [components, pipes, directives],
    exports: [components, pipes, directives, BaseLibModule]
})
export class ComponentsModule {
}
