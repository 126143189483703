import {LOCALE_ID, NgModule} from '@angular/core';

import {ViewsModule} from './views/views.module';
import {CommonModule, registerLocaleData} from '@angular/common';
import {Observable} from 'rxjs';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {ServicesModule} from './services/services.module';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {AppRootComponent} from './app-root.component';
import {RouterModule} from '@angular/router';

registerLocaleData(localeFr, 'fr');

export function TranslateLoaderFactory(http: HttpClient) {
    return {
        getTranslation(lang: string): Observable<any> {
            const options = {headers: new HttpHeaders().append('Accept-Language', lang)};
            return http.get('api/i18n/labels.json', options);
        }
    };
}

@NgModule({
    imports: [
        // 3rd party modules
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-right'
        }),

        // app modules
        ServicesModule,
        ViewsModule,
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'fr'}
    ],
    exports: [],
    declarations: [AppRootComponent],
    bootstrap: [AppRootComponent]
})
export class AppRootModule {
}
