import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PassengerBookingQuotaDto} from '../models/passenger.booking.quota';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Injectable()
export class BookingPassengerService {

    constructor(private http: HttpClient) {}

    public computeRemainingBookingAmount(passengerId: string) {
        return this.http
            .get<PassengerBookingQuotaDto>(`api/passenger/${passengerId}/booking-quotas`)
            .pipe(map(dtos => plainToClass(PassengerBookingQuotaDto, dtos)));
    }
}
