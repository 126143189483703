import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CompanyDto} from 'base-lib';
import {CompanyService} from '../../../services/company.service';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompanySelectComponent),
    multi: true
};

@Component({
    selector: 'company-select',
    templateUrl: './company-select.component.html',
    styleUrls: ['./company-select.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class CompanySelectComponent implements OnInit, ControlValueAccessor {

    companies: CompanyDto[];
    selection: CompanyDto;
    disabled: boolean;

    constructor(private companyService: CompanyService) {}

    ngOnInit(): void {
        this.companyService
            .findAllCompanies()
            .subscribe(companies => {
                this.companies = companies
            });
    }

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(obj: any): void {
        if (this.companies) {
            this.selection = this.companies.find(company => company.id === obj.id);
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}

