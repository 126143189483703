import { Component, Input } from '@angular/core';
import { PersonState } from 'base-lib';

@Component({
  selector: 'person-state',
  templateUrl: './person-state.component.html',
  styleUrls: ['./person-state.component.scss']
})
export class PersonStateComponent {

    @Input() state: PersonState;

}
