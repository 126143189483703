import {Component, forwardRef} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {PostalAddressDto} from 'base-lib';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PostalAddressesEditorComponent),
    multi: true
};

@Component({
    selector: 'postal-addresses-editor',
    templateUrl: './postal-addresses-editor.component.html',
    styleUrls: ['./postal-addresses-editor.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class PostalAddressesEditorComponent implements ControlValueAccessor {

    public postalAddresses: PostalAddressDto[];
    public disabled: boolean;

    onChange = (value: any) => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.postalAddresses = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public addAddress(): void {
        if (!this.postalAddresses) {
            this.postalAddresses = [];
        }
        const postalAddressDto = new PostalAddressDto();
        if (this.postalAddresses.length === 0) {
            postalAddressDto.principal = true;
        }
        this.postalAddresses.push(postalAddressDto);
        this.onChange(this.postalAddresses);
    }

    public removeAddress(address: PostalAddressDto): void {
        this.postalAddresses = this.postalAddresses.filter(a => a !== address);
        this.onChange(this.postalAddresses);
    }

    public onAddressChange(postalAddress: PostalAddressDto): void {
        if (this.postalAddresses.length === 1) {
            postalAddress.principal = true;
        }
        if (postalAddress.principal === true) {
            this.postalAddresses
                .filter(aPostalAddress => aPostalAddress !== postalAddress)
                .forEach(address => address.principal = false);
        }
        this.onChange(this.postalAddresses);
    }
}
