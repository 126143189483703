import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PersonService} from '../../../../services/person.service';
import {SessionService} from 'auth-lib';
import {filter, switchMap} from 'rxjs/operators';
import {AlertService, PersonDto} from 'base-lib';
import {Router} from '@angular/router';

@Component({
    selector: 'person-security-profile',
    templateUrl: './person-security-profile.component.html',
    styleUrls: ['./person-security-profile.component.scss']
})
export class PersonSecurityProfileComponent implements OnInit {

    @Input() person: PersonDto;
    @Output() resultsChange: EventEmitter<PersonDto> = new EventEmitter();
    public currentPerson: PersonDto;
    private parents: PersonDto[] = [];

    constructor(private alertService: AlertService,
                private router: Router,
                private sessionService: SessionService,
                private personService: PersonService) {
    }

    ngOnInit(): void {
        this.personService
            .currentPerson
            .subscribe(person => this.currentPerson = person);
        this.personService
            .findParents(this.person.id)
            .subscribe(parents => this.parents = parents)
    }

    public resetPassword(): void {
        this.alertService
            .confirm('notification.confirm', 'notification.confirm.resetPassword')
            .pipe(
                filter(response => response === true),
                switchMap(response => this.personService.resetPassword(this.person)),
                switchMap(_ => this.alertService.success('notification.success', 'notification.success.passwordReset'))
            )
            .subscribe(
                _ => this.resultsChange.emit(this.person)
            );
    }

    public archive(): void {
        this.alertService
            .confirm('notification.confirm', 'notification.confirm.archive')
            .pipe(
                filter(response => response === true),
                switchMap(response => this.personService.archive(this.person)),
                switchMap(_ => this.alertService.success('notification.success', 'notification.success.subscriberArchived'))
            )
            .subscribe(
                _ => {
                    if (this.person &&
                        this.currentPerson &&
                        this.person.id === this.currentPerson.id) {
                        this.sessionService.logout().subscribe();
                    }
                    this.resultsChange.emit(this.person);
                }
            );
    }

    public enable(): void {
        this.alertService
            .confirm('notification.confirm.activate', 'notification.confirm.activate.description')
            .pipe(
                filter(response => response === true),
                switchMap(response => this.personService.updatePersonState(this.person, 'ACTIVE')),
                switchMap(_ => this.alertService.success('notification.success', 'notification.success.subscriberActivated'))
            )
            .subscribe(
                _ => {
                    if (this.person &&
                        this.currentPerson &&
                        this.person.id === this.currentPerson.id) {
                        this.sessionService.logout().subscribe();
                    }
                    this.resultsChange.emit(this.person);
                }
            );
    }

    public unlink(): void {
        this.alertService
            .confirm('notification.confirm', 'notification.confirm.unlinkChild')
            .pipe(
                filter(response => response === true),
                switchMap(_ => this.personService.unlink(this.person)),
                switchMap(_ => this.alertService.success('notification.success', 'notification.success.unlinkChild')),
                switchMap(_ => this.router.navigate(['/app/account/current/linked']))
            )
            .subscribe(_ => this.resultsChange.emit(this.person));
    }

    public isArchived(): boolean {
        return this.person.state === 'ARCHIVED';
    }

    public hasEmail(): boolean {
        return this.person.email !== undefined
            && this.person.email !== null
            && this.person.email !== '';
    }

    public canBeUnlinked(): boolean {
        return this.person &&
            this.person.email &&
            this.parents.length > 0;
    }
}
