import {Component, forwardRef, Input} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {UserDto} from 'auth-lib';
import {Regex} from 'base-lib';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UserEditorComponent),
    multi: true
};

@Component({
    selector: 'user-editor',
    templateUrl: './user-editor.component.html',
    styleUrls: ['./user-editor.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class UserEditorComponent implements ControlValueAccessor {

    public user: UserDto;
    public disabled: boolean;
    public patternName: string = Regex.withoutSpecialCharacters;
    @Input() displayCompany: boolean;

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.user = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
