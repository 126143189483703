import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import {
    ApiHttpInterceptor,
    AuthLibModule,
    IsOperatorUserAuthenticatedGuard,
    IsStdUserAuthenticatedGuard,
    IsUserAuthenticatedGuard,
    OperatorRedirectionGuard
} from 'auth-lib';
import { BaseLibModule, BusinessErrorHttpInterceptor, PendingRequestsHttpInterceptor } from 'base-lib';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

const routes: Route[] = [
    {path: '', redirectTo: '/app/home', pathMatch: 'full'},
    {path: 'subscription', loadChildren: './subscription/subscription.module#SubscriptionModule'},
    {
        path: 'app',
        loadChildren: './app/app.module#AppModule',
        canLoad: [IsUserAuthenticatedGuard, OperatorRedirectionGuard, IsStdUserAuthenticatedGuard]
    },
    {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule',
        canLoad: [IsUserAuthenticatedGuard, IsOperatorUserAuthenticatedGuard]
    },
    {path: '**', redirectTo: '/app/home'}
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'}),
        AuthLibModule.forRoot(),
        BaseLibModule.forRoot(),
    ],
    providers: [
        IsUserAuthenticatedGuard,
        IsStdUserAuthenticatedGuard,
        IsOperatorUserAuthenticatedGuard,
        OperatorRedirectionGuard,
        {provide: HTTP_INTERCEPTORS, useClass: ApiHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: PendingRequestsHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: BusinessErrorHttpInterceptor, multi: true},
    ],
    exports: [RouterModule]
})
export class ViewsRoutingModule {
}
