import { Component, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import * as _ from 'lodash';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AccountAccessMailListComponent),
    multi: true
};

@Component({
    selector: 'account-access-mail-list',
    templateUrl: './account-access-mail-list.component.html',
    styleUrls: ['./account-access-mail-list.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class AccountAccessMailListComponent implements ControlValueAccessor {

    public emails: EMailAddress[];
    public disabledEmails: string[] = [];
    public disabled: boolean;

    onChange = (value: any) => {
    }

    onTouched = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.emails = [];
        if (obj) {
            this.emails = obj.map(address => new EMailAddress(address));
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public addEmail(): void {
        if (!this.emails) {
            this.emails = [];
        }
        this.emails.push(new EMailAddress(''));
        this.notifyChange();
    }

    public removeEmail(email: EMailAddress): void {
        this.emails = this.emails.filter(e => e !== email);
        this.notifyChange();
    }

    public notifyChange() {
        this.onChange(_.uniq(this.emails
            .filter(value => value.address !== '')
            .map(a => a.address)
        ));
    }

    public disableEmail(email: string): void {
        this.disabledEmails.push(email);
    }

    public isEmailDisabled(email: EMailAddress): boolean {
        return this.disabledEmails && this.disabledEmails.indexOf(email.address) >= 0;
    }
}

export class EMailAddress {
    address: string;
    constructor(address: string) {
        this.address = address;
    }
}

