import {Component, Input, OnInit} from '@angular/core';
import {BookingPassengerService} from '../../../services/booking-passenger.service';
import {PassengerBookingQuotaDto} from '../../../models/passenger.booking.quota';

@Component({
    selector: 'booking-quotas',
    templateUrl: './booking-quotas.component.html',
    styleUrls: ['./booking-quotas.component.scss']
})
export class BookingQuotasComponent implements OnInit {

    @Input() personId: string;
    private bookingQuota: PassengerBookingQuotaDto;

    constructor(private bookingPassengerService: BookingPassengerService) {}

    ngOnInit() {
        console.log(this.personId)
        this.bookingPassengerService.computeRemainingBookingAmount(this.personId)
            .subscribe(value => this.bookingQuota = value)
    }
}
