import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService, EnumService, FileRef, FileService, PersonDto } from 'base-lib';
import { DocumentService } from '../../../services/document.service';
import { DocumentDto, DocumentKind } from '../../../models/document.model';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'person-documents',
    templateUrl: './person-documents.component.html',
    styleUrls: ['./person-documents.component.scss']
})
export class PersonDocumentsComponent implements OnInit {

    @Input() person: PersonDto;
    @Output() personChange: EventEmitter<PersonDto> = new EventEmitter<PersonDto>();
    documents: DocumentDto[];
    private documentKinds: DocumentKind[];

    constructor(private documentService: DocumentService,
                private enumService: EnumService,
                private fileService: FileService,
                private alertService: AlertService) {}

    ngOnInit() {
        this.enumService
            .fetchValues('documentKinds')
            .pipe(
                map(kinds => kinds as DocumentKind[]),
                tap(kinds => this.documentKinds = kinds),
                switchMap(kinds => this.loadPersonDocuments(kinds))
            )
            .subscribe();
    }

    private loadPersonDocuments(documentsKinds: DocumentKind[]): Observable<DocumentDto[]> {
        return this.documentService.findByPerson(this.person)
            .pipe(
                map(documents => this.loadDocuments(documentsKinds as DocumentKind[], documents)),
                tap(documents => this.documents = documents)
            );
    }

    private loadDocuments(documentsKinds: DocumentKind[], documents: DocumentDto[]): DocumentDto[] {
        return documentsKinds
            .map(kind => documents.find(doc => doc.kind === kind) || this.buildNotProvidedDocument(kind));
    }

    private buildNotProvidedDocument(kind: DocumentKind): DocumentDto {
        const documentDto = new DocumentDto();
        documentDto.kind = kind;
        return documentDto;
    }

    public onDocumentPicked(document: DocumentDto, fileRef: FileRef): void {
        this.fileService
            .uploadFile(fileRef)
            .pipe(
                tap(aFileRef => document.binaryDataId = aFileRef.id),
                tap(aFileRef => document.fileName = aFileRef.file.name),
                switchMap(_ => this.documentService.attach(this.person, document)),
                switchMap(_ => this.loadPersonDocuments(this.documentKinds))
            )
            .subscribe(_ => this.personChange.emit(this.person));
    }

    public deleteDocument(document: DocumentDto): void {
        this.alertService
            .confirm('notification.confirm', 'notification.confirm.deleteDocument')
            .pipe(
                filter(response => response === true),
                switchMap(_ => this.documentService.detach(this.person, document)),
                switchMap(_ => this.loadPersonDocuments(this.documentKinds))
            )
            .subscribe(_ => this.personChange.emit(this.person));
    }
}
