import { Component, forwardRef } from '@angular/core';
import { AbstractSubscriptionStepComponent } from '../abstract-subscription-step.component';
import { AbstractStepContentComponent } from 'base-lib';

@Component({
    selector: 'subscription-type',
    templateUrl: './subscription-type.component.html',
    styleUrls: ['./subscription-type.component.scss'],
    providers: [{
        provide: AbstractStepContentComponent,
        useExisting: forwardRef(() => SubscriptionTypeComponent),
        multi: true
    }]
})
export class SubscriptionTypeComponent extends AbstractSubscriptionStepComponent {

}
