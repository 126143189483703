import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CompanyDto} from 'base-lib';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Injectable()
export class CompanyService {

    constructor(private http: HttpClient) {}

    public findCompanies(): Observable<CompanyDto[]> {
        return this.http.get<CompanyDto[]>(`api/company`);
    }

    public findAllCompanies(): Observable<CompanyDto[]> {
        return this.http
            .get<CompanyDto[]>(`api/company`)
            .pipe(map(dtos => plainToClass(CompanyDto, dtos)));
    }

    public findCompanyById(id: string): Observable<CompanyDto> {
        return this.http
            .get<CompanyDto>(`api/company/${id}`)
            .pipe(map(dtos => plainToClass(CompanyDto, dtos)));
    }

    public findCompany(id: string): Observable<CompanyDto> {
        return this.http.get<CompanyDto>(`api/company/${id}`);
    }

    public create(company: CompanyDto): Observable<CompanyDto> {
        return this.http.post<CompanyDto>(`api/company`, company);
    }

    public update(id: string, company: CompanyDto): Observable<CompanyDto> {
        return this.http.put<CompanyDto>(`api/company/${id}`, company);
    }
}
