import {DocumentDto} from './document.model';
import {PersonDto} from 'base-lib';
import {QuestionnaireDto} from './questionnaire.model';

export class SubscriptionDto {

    kind: string;
    person: PersonDto;
    documents: DocumentDto[];
    parentPersonEmails: string[];
    questionnaire: QuestionnaireDto;

    constructor() {
        this.person = new PersonDto();
        this.documents = [];
        this.parentPersonEmails = [];
        this.questionnaire = new QuestionnaireDto();
    }
}
