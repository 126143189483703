import { Component, Input } from '@angular/core';
import { PersonDto } from 'base-lib';
import { FileService } from 'base-lib';

@Component({
    selector: 'person-card',
    templateUrl: './person-card.component.html',
    styleUrls: ['./person-card.component.scss']
})
export class PersonCardComponent {

    @Input() person: PersonDto;
    @Input() mode: 'short' | 'full' = 'full';

    constructor(public fileService: FileService) {}

    public isFullMode(): boolean {
        return this.mode === 'full';
    }

    public isShortMode(): boolean {
        return this.mode === 'short';
    }
}
