import { Component, forwardRef, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, NgModel } from '@angular/forms';
import { PersonDto, Regex } from 'base-lib';
import { SubscriptionMode } from '../../../models/subscription-mode.mode';
import * as moment from 'moment';
import { Moment } from 'moment';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PersonEditorComponent),
    multi: true
};

@Component({
    selector: 'person-editor',
    templateUrl: './person-editor.component.html',
    styleUrls: ['./person-editor.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class PersonEditorComponent implements ControlValueAccessor {

    @Input() mode: SubscriptionMode = 'STANDARD';
    private _kind: string;
    public birthDateMaxDate: Moment;
    public birthDateMinDate: Moment;
    public person: PersonDto;
    public disabled: boolean;
    public patternName: string = Regex.withoutSpecialCharacters;

    @Input()
    public get kind(): string {
        return this._kind;
    }

    public set kind(kind: string) {
        this._kind = kind;
        this.updateDateRanges();
    }

    private updateDateRanges(): void {
        this.birthDateMinDate = this.getBirthDateMinDate();
        this.birthDateMaxDate = this.getBirthDateMaxDate();
    }

    onChange = (_: any) => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.person = obj;
        this.updateDateRanges();
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public invalidFields(phoneNumber: string, cellPhoneNumber: string) {
        return this.isEmptyPhoneNumber(phoneNumber) && this.isEmptyPhoneNumber(cellPhoneNumber);
    }

    public isEmptyPhoneNumber(phone: string): boolean {
        return !phone || phone.length < 5;
    }

    public isCellPhoneRequired(cellPhoneNumber: NgModel): boolean {
        return cellPhoneNumber.invalid || this.invalidFields(this.person.phoneNumber, this.person.cellPhoneNumber);
    }

    public getBirthDateMaxDate() {
        if (this.person && !this.person.id) {
            return this.kind === 'MINOR' ? moment().subtract(8, 'years') : moment().subtract(18, 'years');
        }
        return moment().subtract(8, 'years');
    }

    public getBirthDateMinDate() {
        if (this.person && !this.person.id) {
            return this.kind === 'MINOR' ? moment().subtract(18, 'years') : moment().subtract(200, 'years');
        }
        return moment().subtract(200, 'years');
    }

    public isEmailRequired(): boolean {
        if (this.person && !this.person.id) {
            return this.mode === 'STANDARD';
        } else if (this.person && this.person.id) {
            return this.person.email !== undefined && this.person.email !== null;
        }
        return true;
    }
}
