import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PersonDto, PostalAddressDto } from 'base-lib';

@Injectable()
export class PostalAddressService {

    constructor(private http: HttpClient) { }

    public findByPerson(person: PersonDto): Observable<PostalAddressDto[]> {
        return this.http.get<PostalAddressDto[]>(`api/person/${person.id}/postalAddress`);
    }
}
