import { Component, forwardRef, ViewChild } from '@angular/core';
import { AbstractSubscriptionStepComponent } from '../../subscription/abstract-subscription-step.component';
import { AbstractStepContentComponent } from 'base-lib';
import { AccountAccessMailListComponent } from '../account-access-mail-list/account-access-mail-list.component';

@Component({
    selector: 'account-access',
    templateUrl: './account-access.component.html',
    styleUrls: ['./account-access.component.scss'],
    providers: [{
        provide: AbstractStepContentComponent,
        useExisting: forwardRef(() => AccountAccessComponent),
        multi: true
    }]
})
export class AccountAccessComponent extends AbstractSubscriptionStepComponent {

    public createUser: boolean;

    @ViewChild(AccountAccessMailListComponent) mailsList: AccountAccessMailListComponent;

    public disableEmail(email: string): void {
        this.mailsList.disableEmail(email);
    }
}
