import { Component, Input } from '@angular/core';
import { DocumentService } from '../../../services/document.service';
import { DocumentDto } from '../../../models/document.model';
import { PostalAddressService } from '../../../services/postal-address.service';
import { PersonDto } from 'base-lib';
import { PostalAddressDto } from 'base-lib';
import { FileService } from 'base-lib';

@Component({
    selector: 'person-profile',
    templateUrl: './person-profile.component.html',
    styleUrls: ['./person-profile.component.scss']
})
export class PersonProfileComponent {

    private _person: PersonDto;

    public proofOfAddressDocument: DocumentDto;
    public proofOfHandicapDocument: DocumentDto;
    public principalPostalAddress: PostalAddressDto;

    constructor(public fileService: FileService,
                private documentService: DocumentService,
                private postalAddressService: PostalAddressService) {
    }

    @Input()
    public set person(person: PersonDto) {
        this._person = person;
        this.postalAddressService
            .findByPerson(person)
            .subscribe(addresses => this.loadPostalAddress(addresses));
        this.documentService
            .findByPerson(person)
            .subscribe(documents => this.loadDocuments(documents));
    }

    public get person() {
        return this._person;
    }

    private loadDocuments(documents: DocumentDto[]) {
        this.proofOfAddressDocument = documents.find(document => document.kind === 'ADDRESS_PROOF');
        this.proofOfHandicapDocument = documents.find(document => document.kind === 'HANDICAP_PROOF');
    }

    private loadPostalAddress(addresses: PostalAddressDto[]) {
        this.principalPostalAddress = this.getPrincipalAddress(addresses);
    }

    private getPrincipalAddress(addresses: PostalAddressDto[]) {
        if (addresses.length > 1) {
            return addresses.find(postalAddress => postalAddress.principal);
        } else if (addresses.length === 1) {
            return addresses[0];
        }
        return null;
    }
}
