import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PersonGender } from 'base-lib';
import { EnumService } from 'base-lib';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PersonGenderSelectComponent),
    multi: true
};

@Component({
    selector: 'person-gender-select',
    templateUrl: './person-gender-select.component.html',
    styleUrls: ['./person-gender-select.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class PersonGenderSelectComponent implements OnInit, ControlValueAccessor {

    public personGender: PersonGender;
    public personGenders: string[];
    public disabled: boolean;

    constructor(private enumService: EnumService) {}

    ngOnInit(): void {
        this.enumService
            .fetchValues('personGenders')
            .subscribe(genders => this.personGenders = genders)
    }

    onChange = (value: any) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.personGender = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
