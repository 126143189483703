import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyDto } from 'base-lib';

@Component({
  selector: 'company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent {

    @Input() companies: CompanyDto[];
    @Output() selected: EventEmitter<CompanyDto> = new EventEmitter();

}
