import { DocumentDto, DocumentKind } from './document.model';
import { FileRef } from 'base-lib';

export class SubscriptionContext {

    pictureToUpload: FileRef;
    documentsToUpload: DocumentToUpload[] = [];

    public hasDocument(kind: DocumentKind): boolean {
        return this.documentsToUpload
            .filter(document => document.document !== undefined && document.document !== null)
            .findIndex(document => document.document.kind === kind) >= 0;
    }
}

export class DocumentToUpload {
    file: FileRef;
    document: DocumentDto;
}
