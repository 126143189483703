import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { EnumService } from 'base-lib';
import { HttpParams } from '@angular/common/http';
import { SubscriptionMode } from '../../../../models/subscription-mode.mode';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SubscriptionTypeSelectComponent),
    multi: true
};

@Component({
    selector: 'subscription-type-select',
    templateUrl: './subscription-type-select.component.html',
    styleUrls: ['./subscription-type-select.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SubscriptionTypeSelectComponent implements OnInit, ControlValueAccessor {

    @Input() mode: SubscriptionMode = 'STANDARD';

    public kinds: string[];
    public kind: string;
    public selectedKind: string;
    public disabled: boolean;

    constructor(private enumService: EnumService) {}

    ngOnInit(): void {
        const httpParams = new HttpParams().append('mode', this.mode);
        this.enumService
            .fetchValues('subscriptionKinds', httpParams)
            .subscribe(kinds => this.kinds = kinds)
    }

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.kind = obj;
        this.selectedKind = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public select(kind: string): void {
        this.kind = kind;
        this.selectedKind = kind;
        this.onChange(kind);
    }
}
