import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {PersonDto} from 'base-lib';
import {catchError, tap} from 'rxjs/operators';
import {PersonSearchCriteria} from '../models/person-search.model';

@Injectable()
export class PersonService {

    public currentPerson: Subject<PersonDto> = new BehaviorSubject(undefined);

    constructor(private http: HttpClient) {}

    public findCurrentPerson(): Observable<PersonDto> {
        return this.http
            .get<PersonDto>(`api/person/current`)
            .pipe(
                catchError(e => of(null)),
                tap(person => this.currentPerson.next(person)),
            );
    }

    public findPersons(criteria: PersonSearchCriteria): Observable<PersonDto[]> {
        const params = criteria.toSearchParams();
        return this.http.get<PersonDto[]>(`api/person`, {params});
    }

    public downloadExportPersons(criteria: PersonSearchCriteria): Observable<any> {
        const params: HttpParams = criteria.toSearchParams();
        return this.http.get(`api/person/export`, {params, responseType: 'text'});
    }

    public findPerson(id: string): Observable<PersonDto> {
        return this.http.get<PersonDto>(`api/person/${id}`);
    }

    public findParents(id: string): Observable<PersonDto[]> {
        return this.http.get<PersonDto[]>(`api/person/${id}/parent`);
    }

    public findChildren(id: string): Observable<PersonDto[]> {
        return this.http.get<PersonDto[]>(`api/person/${id}/child`);
    }

    public findDuplicates(id: string): Observable<PersonDto[]> {
        return this.http.get<PersonDto[]>(`api/person/${id}/duplicate`);
    }

    public updatePerson(id: string, person: PersonDto): Observable<PersonDto> {
        return this.http.put<PersonDto>(`api/person/${id}`, person);
    }

    public updatePersonState(person: PersonDto, state: string): Observable<PersonDto> {
        return this.http.put<PersonDto>(`api/person/${person.id}/state/${state}`, {});
    }

    public resetPassword(person: PersonDto): Observable<any> {
        return this.http.delete(`api/person/${person.id}/credentials`);
    }

    public archive(person: PersonDto): Observable<PersonDto> {
        return this.http.delete<PersonDto>(`api/person/${person.id}`);
    }

    public link(parent: PersonDto, child: PersonDto): Observable<PersonDto[]> {
        return this.http.post<PersonDto[]>(`api/person/${parent.id}/child/${child.id}`, {});
    }

    public unlink(child: PersonDto): Observable<PersonDto> {
        return this.http.delete<PersonDto>(`api/person/${child.id}/parent`, {});
    }
}
