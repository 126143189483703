import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {SubscriptionService} from './subscription.service';
import {PersonService} from './person.service';
import {DocumentService} from './document.service';
import {PostalAddressService} from './postal-address.service';
import {UserService} from './user.service';
import {CompanyService} from './company.service';
import {SettingsService} from './settings.service';
import {BookingPassengerService} from './booking-passenger.service';

@NgModule({
    imports: [
        HttpClientModule,
        ToastrModule
    ],
    providers: [
        SubscriptionService,
        PersonService,
        UserService,
        DocumentService,
        PostalAddressService,
        CompanyService,
        SettingsService,
        BookingPassengerService
    ]
})
export class ServicesModule {
}
