import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

const routes: Route[] = [
    {
        path: '',
        component: AppComponent,
        children: [
            {path: 'home', loadChildren: './home/home.module#HomeModule'},
            {path: 'account', loadChildren: './account/account.module#AccountModule'}
        ]
    }
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forChild(routes)
    ],
    providers: [],
    exports: []
})
export class AppRoutingModule {
}
