import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserDto} from 'auth-lib';

@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    host: {class: 'cell auto cell-block-y'}
})
export class UserListComponent {

    @Input() users: UserDto[];
    @Output() selected: EventEmitter<UserDto> = new EventEmitter();

    isAnyUserHavingCompany() {
        if (this.users == null) {
            return false;
        }
        return this.users.some(user => user.company != null);
    }
}
