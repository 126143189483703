import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubscriptionDto } from '../models/subscription.model';
import { PersonDto } from 'base-lib';

@Injectable()
export class SubscriptionService {

    constructor(private http: HttpClient) { }

    public send(subscription: SubscriptionDto): Observable<PersonDto> {
        return this.http.post<PersonDto>(`api/subscription`, subscription);
    }
}
