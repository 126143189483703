export class DocumentDto {
    id: string;
    fileName: string;
    kind: DocumentKind;
    author: string;
    date: Date;
    binaryDataId: string;
}

export type DocumentKind = 'ADDRESS_PROOF' | 'HANDICAP_PROOF';
