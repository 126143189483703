import {Component, ElementRef, forwardRef, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {Foundation} from 'foundation-sites/js/foundation.core';

import * as jQuery_ from 'jquery';
import {PostalAddressDto, PostalAddressService} from 'base-lib';

const jQuery = jQuery_;

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PostalAddressSearchComponent),
    multi: true
};

@Component({
    selector: 'postal-address-search',
    templateUrl: './postal-address-search.component.html',
    styleUrls: ['./postal-address-search.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class PostalAddressSearchComponent implements OnInit, ControlValueAccessor {

    public id: string = Math.random().toString(36);
    public postalAddressQuerySubject: Subject<string> = new Subject<string>();
    public postalAddressQuery: string;
    public postalAddress: PostalAddressDto;
    public results: PostalAddressDto[];
    public disabled: boolean;

    @ViewChild('resultsDropdown') public resultsDropdownEl: ElementRef;
    private resultsDropdown: Foundation.Dropdown;

    constructor(private postalAddressService: PostalAddressService) {
    }

    ngOnInit(): void {
        this.postalAddressQuerySubject
            .pipe(
                filter(addr => addr && addr.length > 3),
                debounceTime(500),
                switchMap(addr => this.postalAddressService.normalize(addr)),
                tap(_ => {
                    this.resultsDropdown = new Foundation.Dropdown(jQuery(this.resultsDropdownEl.nativeElement));
                    return this.resultsDropdown.open();
                }),
            )
            .subscribe(addresses => this.results = addresses, e => {
            });
    }

    onChange = (_: any) => {
    }

    onTouched = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    createPostalAddressQuery() {
        if (this.postalAddress && this.postalAddress.address) {
            this.postalAddressQuery = `${this.postalAddress.address} - ${this.postalAddress.zipCode} ${this.postalAddress.city}`;
        }
    }

    writeValue(obj: any): void {
        this.postalAddress = obj;
        this.postalAddressQuery = '';
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onAddressChange(address: string): void {
        this.postalAddressQuerySubject.next(address);
    }

    public submit(result: PostalAddressDto): void {
        this.resultsDropdown.close();
        this.writeValue(result);
        this.onChange(result);
    }

}
