import {Component, forwardRef} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {QuestionnaireDto} from '../../../../models/questionnaire.model';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SubscriptionQuestionnaireComponent),
    multi: true
};

@Component({
    selector: 'subscription-questionnaire',
    templateUrl: './subscription-questionnaire.component.html',
    styleUrls: ['./subscription-questionnaire.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SubscriptionQuestionnaireComponent implements ControlValueAccessor {

    public questionnaire: QuestionnaireDto;

    onChange = (_: any) => {
    }

    onTouched = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.questionnaire = obj;
    }

}
